<template>
  <b-card title="USER INFORMATION">
    <!--USER INFORMATION-->
    <validationObserver ref="formUserInformation">
      <b-container fluid class="mx-1 pr-3 my-1">
        <b-row>
          <!-- User Info: Left col -->
          <b-col
            cols="12"
            xl="3"
            md="12"
            class="d-flex justify-content-between flex-column mb-lg-0 mb-md-3 mb-md-3 mb-sm-3 mb-3"
          >
            <!-- User Avatar & Action Buttons -->
            <div class="d-flex justify-content-start">
              <div
                v-if="form.file != ''"
                :class="['avatar-container', { backdrop: form.remove }]"
              >
                <div
                  v-if="form.remove"
                  class="remove-temporal-avatar"
                  @click="clearFileData"
                >
                  <div>
                    <feather-icon icon="XIcon" size="35" />
                  </div>
                </div>
                <b-avatar
                  ref="previewEl"
                  :src="form.file"
                  :text="avatarText(userData.fullName)"
                  size="130px"
                  rounded
                />
              </div>
              <b-avatar
                v-else
                :src="userData.avatar"
                :text="avatarText(userData.fullName)"
                :variant="`light-primary`"
                size="130px"
                rounded
              />
              <div class="ml-2">
                <div class="mb-1">
                  <h4 class="mb-0">
                    <b>
                      {{ userData.fullName }}
                    </b>
                  </h4>
                  <span class="card-text">{{ userData.email }}</span>
                </div>

                <b-button variant="outline-primary" size="sm" @click="addFile">
                  <b-form-file
                    ref="refInputEl"
                    class="d-none"
                    @change="fillFileData"
                  />
                  <feather-icon icon="ImageIcon" class="d-inline mr-25" />
                  <span class="d-none d-sm-inline">Upload</span>
                </b-button>
              </div>
            </div>
          </b-col>

          <!-- Right Col: Table -->
          <b-col cols="12" xl="3" md="6" class="information-form">
            <!-- <validation-provider
              v-slot="{errors}"
              name="type-document"
              rules="required"
            > -->
            <b-form-group
              label-cols-lg="5"
              label-cols-md="5"
              label-cols-sm="4"
              label-cols="12"
              content-cols-lg="7"
              content-cols-md="7"
              content-cols-sm="8"
              content-cols="12"
            >
              <template #label>
                <feather-icon icon="UserIcon" class="mr-75" />
                Type Document
              </template>

              <v-select
                v-model="userData.pid_type"
                :clearable="false"
                :options="optionsTypeDocument"
                :reduce="(option) => option.id"
                label="name"
                placeholder="--Select option--"
                :disabled="true"
              />
            </b-form-group>
            <!-- </validation-provider> -->
            <!-- <validation-provider
              v-slot="{errors}"
              name="N-Document"
              :rules="userData.pid_type === 1 ? 'required|digits:8|max:20':'required|max:20'"
            > -->
            <b-form-group
              label-cols-lg="5"
              label-cols-md="5"
              label-cols-sm="4"
              label-cols="12"
              content-cols-lg="7"
              content-cols-md="7"
              content-cols-sm="8"
              content-cols="12"
            >
              <template #label>
                <feather-icon icon="UserIcon" class="mr-75" />
                N. Document
              </template>

              <b-form-input
                v-model="userData.pid"
                placeholder="N. document"
                :disabled="true"
              />
              <!-- <span
                  v-if="errors[0]"
                  class="text-danger"
                >{{ errors[0] }}</span> -->
            </b-form-group>
            <!-- </validation-provider> -->

            <b-form-group
              label-cols-lg="5"
              label-cols-md="5"
              label-cols-sm="4"
              label-cols="12"
              content-cols-lg="7"
              content-cols-md="7"
              content-cols-sm="8"
              content-cols="12"
            >
              <template #label>
                <feather-icon icon="UserIcon" class="mr-75" />
                First Name
              </template>

              <b-form-input
                v-model="userData.first_name"
                placeholder="Fist name"
                :disabled="true"
              />
            </b-form-group>
            <b-form-group
              label-cols-lg="5"
              label-cols-md="5"
              label-cols-sm="4"
              label-cols="12"
              content-cols-lg="7"
              content-cols-md="7"
              content-cols-sm="8"
              content-cols="12"
            >
              <template #label>
                <feather-icon icon="UserIcon" class="mr-75" />
                Middle Name
              </template>

              <b-form-input
                v-model="userData.middle_name"
                placeholder="Middle name"
                :disabled="middleName ? true : false"
              />
            </b-form-group>
            <b-form-group
              label-cols-lg="5"
              label-cols-md="5"
              label-cols-sm="4"
              label-cols="12"
              content-cols-lg="7"
              content-cols-md="7"
              content-cols-sm="8"
              content-cols="12"
            >
              <template #label>
                <feather-icon icon="UserIcon" class="mr-75" />
                Last Name
              </template>

              <b-form-input
                v-model="userData.last_name"
                placeholder="Last name"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" xl="3" md="6" class="information-form">
            <validation-provider
              v-slot="{ errors }"
              rules="alpha_spaces|max:50|min:5"
              name="know as"
            >
              <b-form-group
                label-cols-lg="4"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon icon="UserIcon" class="mr-75" />
                  Know as
                </template>

                <b-form-input
                  v-model="userData.know_as"
                  placeholder="Know us"
                  :state="errors[0] ? false : null"
                  disabled
                />
                <span v-if="errors[0]" class="text-danger">{{
                  errors[0]
                }}</span>
              </b-form-group>
            </validation-provider>
            <b-form-group
              label-cols-lg="4"
              label-cols-md="5"
              label-cols-sm="4"
              label-cols="12"
              content-cols-lg="7"
              content-cols-md="7"
              content-cols-sm="8"
              content-cols="12"
            >
              <template #label>
                <feather-icon icon="PhoneIcon" class="mr-75" />
                Extension
              </template>

              <b-form-input
                v-model="userData.extension"
                placeholder="Extension"
              />
            </b-form-group>
            <validation-provider
              v-slot="{ errors }"
              name="phone"
              rules="max:15"
            >
              <b-form-group
                label-cols-lg="4"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon icon="StarIcon" class="mr-75" />
                  Phone
                </template>
                <b-form-input
                  v-model="userData.phone"
                  v-mask="'(###)-###-####'"
                  placeholder="Phone"
                  :class="errors[0] ? 'border-danger' : ''"
                />
                <span v-if="errors[0]" class="text-danger">{{
                  errors[0]
                }}</span>
              </b-form-group>
            </validation-provider>
            <validation-provider v-slot="{ errors }" name="mobile" rules="">
              <b-form-group
                label-cols-lg="4"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon icon="PhoneIcon" class="mr-75" />
                  Mobile :
                </template>

                <b-form-input
                  v-model="userData.cellphone"
                  v-mask="'(###)-###-###'"
                  placeholder="Mobile"
                  :class="errors[0] ? 'border-danger' : ''"
                />
              </b-form-group>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="home-phone"
              rules="max:15"
            >
              <b-form-group
                label-cols-lg="4"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon icon="PhoneIcon" class="mr-75" />
                  Home phone :
                </template>

                <b-form-input
                  v-model="userData.work_phone"
                  placeholder="Phone"
                  :class="errors[0] ? 'border-danger' : ''"
                />
                <span v-if="errors[0]" class="text-danger">{{
                  errors[0]
                }}</span>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" xl="3" md="6">
            <validation-provider
              v-slot="{ errors }"
              name="date_of_birth"
              rules="required"
            >
              <b-form-group
                label-cols-lg="5"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon icon="CalendarIcon" class="mr-75" />
                  Date of Birth
                </template>

                <!-- <b-form-datepicker v-model="userData.date_of_birth" /> -->
                <kendo-datepicker
                  id="date"
                  v-model="userData.date_of_birth"
                  v-mask="'##/##/####'"
                  :first-day-of-week="1"
                  placeholder="MM/DD/YYYY"
                  :format="'MM/dd/yyyy'"
                  :state="errors[0] ? false : null"
                  :class="errors[0] ? 'border-danger' : ''"
                  :max="new Date()"
                  class="leads-datepicker w-100 rounded bg-transparent k-picker-custom picker-select-date"
                />
              </b-form-group>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="gender"
              rules="required"
            >
              <b-form-group
                label-cols-lg="5"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon icon="UserIcon" class="mr-75" />
                  Gender :
                </template>
                <b-form-radio-group
                  id="radio-group-gender"
                  v-model="userData.gender"
                  style="margin-top: 8px"
                  :options="optionsGender"
                  name="options-gender"
                  :class="errors[0] ? 'border-danger rounded' : ''"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-container>
      <b-container fluid class="py-2 pt-3">
        <b-row class="rounded px-md-2">
          <b-col cols="12" md="6" xl="4">
            <b-form-group
              label-cols-lg="4"
              label-cols-md="5"
              label-cols-sm="4"
              label-cols="12"
              content-cols-lg="7"
              content-cols-md="7"
              content-cols-sm="8"
              content-cols="12"
            >
              <template #label>
                <feather-icon icon="CalendarIcon" class="mr-75" />
                Age:
              </template>

              <b-form-input v-model="age" readonly placeholder="age" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" xl="4">
            <validation-provider
              v-slot="{ errors }"
              name="marital-staus"
              rules="required"
            >
              <b-form-group
                label-cols-lg="4"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon icon="UserIcon" class="mr-75" />
                  Marital Status:
                </template>

                <v-select
                  v-model="userData.marital_id"
                  :clearable="false"
                  :options="optionsMaritalStatus"
                  :reduce="(option) => option.id"
                  label="name"
                  placeholder="--Select option--"
                  :class="errors[0] ? 'border-danger rounded' : ''"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" xl="4">
            <validation-provider
              v-slot="{ errors }"
              name="personal-email"
              rules="required|email"
            >
              <b-form-group
                label-cols-lg="4"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon icon="MailIcon" class="mr-75" />
                  Personal e-mail:
                </template>

                <b-form-input
                  v-model="userData.private_email"
                  placeholder="personal email"
                  :class="errors[0] ? 'border-danger' : ''"
                />
                <span v-if="errors[0]" class="text-danger">{{
                  errors[0]
                }}</span>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" xl="4">
            <validation-provider
              v-slot="{ errors }"
              name="address"
              rules="required"
            >
              <b-form-group
                label-cols-lg="4"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon icon="MapPinIcon" class="mr-75" />
                  Address:
                </template>
                <vue-google-autocomplete
                  id="address"
                  ref="refAddress"
                  v-model="userData.address"
                  class="form-control input-form"
                  placeholder="Please type your mailing address"
                  :country="userData.country"
                  style="height: 30px !important"
                  :style="errors[0] ? 'border:1px solid red !important;' : ''"
                  :class="errors[0] ? 'rounded border border-danger' : ''"
                  :state="errors[0] ? false : null"
                  @placechanged="getAddressEmployees"
                />

                <!-- <b-form-input
                      v-model="userData.address"
                      placeholder="Address"
                      :class="errors[0]?'border-danger rounded':''"
                    /> -->
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6" xl="4">
            <validation-provider
              v-slot="{ errors }"
              name="city"
              rules="required"
            >
              <b-form-group
                label-cols-lg="4"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon icon="MapPinIcon" class="mr-75" />
                  City:
                </template>

                <b-form-input
                  v-model="userData.city"
                  placeholder="City"
                  :class="errors[0] ? 'border-danger' : ''"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6" xl="4">
            <b-form-group
              label-cols-lg="4"
              label-cols-md="5"
              label-cols-sm="4"
              label-cols="12"
              content-cols-lg="7"
              content-cols-md="7"
              content-cols-sm="8"
              content-cols="12"
            >
              <template #label>
                <feather-icon icon="MapPinIcon" class="mr-75" />
                Zip code:
              </template>

              <b-form-input
                v-model="userData.zip_code"
                placeholder="Zip code"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" xl="4">
            <validation-provider
              v-slot="{ errors }"
              name="city"
              rules="required"
            >
              <b-form-group
                label-cols-lg="4"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon icon="MapPinIcon" class="mr-75" />
                  State:
                </template>

                <b-form-input
                  v-model="userData.state"
                  placeholder="state"
                  :class="errors[0] ? 'border-danger rounded' : ''"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6" xl="4">
            <b-form-group
              label-cols-lg="4"
              label-cols-md="5"
              label-cols-sm="4"
              label-cols="12"
              content-cols-lg="7"
              content-cols-md="7"
              content-cols-sm="8"
              content-cols="12"
            >
              <template #label>
                <feather-icon icon="FlagIcon" class="mr-75" />
                Country
              </template>

              <b-form-input
                readonly
                :value="userData.country == 'PE' ? 'Peru' : 'USA'"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </validationObserver>
    <div class="px-2 my-3">
      <tabs-more-information />
    </div>
  </b-card>
</template>

<script>
import moment from "moment";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { avatarText } from "@core/utils/filter";
import EmployeesService from "@/views/management/views/employees/employees.service";
import TabsMoreInformation from "@/commons/users/user-view/others-views/TabsMoreInformation.vue";

export default {
  components: {
    TabsMoreInformation,
    VueGoogleAutocomplete,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
    },
  },
  data() {
    return {
      optionsGender: [
        {
          value: 1,
          text: "Female",
        },
        {
          value: 2,
          text: "Male",
        },
      ],
      optionsMaritalStatus: [],
      optionsTypeDocument: [],
      optionsRelations: [],
      middleName: null,
    };
  },
  computed: {
    age() {
      const currentYear = moment();
      const dateOfBirth = moment(this.userData.date_of_birth);
      return this.userData.date_of_birth
        ? currentYear.diff(dateOfBirth, "years")
        : null;
    },
  },
  async mounted() {
    this.middleName = this.userData.middle_name;
    await Promise.all([this.getAllDocuments(), this.getAllMartial()]);
  },
  methods: {
    avatarText(fullName) {
      return avatarText(fullName);
    },
    addFile() {
      this.$refs.refInputEl.$el.childNodes[0].click();
    },
    fillFileData(event) {
      const image = event.srcElement.files[0];
      this.form.image = image.name;

      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = () => (this.form.file = reader.result);

      this.form.remove = true;
    },
    clearFileData() {
      this.form.file = "";
      this.form.image = "";
      this.form.remove = false;
      this.$refs.refInputEl.reset();
    },
    async getAllDocuments() {
      try {
        const data = await EmployeesService.getAllDocuments();

        // eslint-disable-next-line no-param-reassign

        this.optionsTypeDocument = data.data;
        // Must return an array of items or an empty array if an error occurred

        return this.optionsTypeDocument;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    async getAllMartial() {
      try {
        const data = await EmployeesService.getAllMartial();

        // eslint-disable-next-line no-param-reassign

        this.optionsMaritalStatus = data.data;
        // Must return an array of items or an empty array if an error occurred

        return this.optionsMaritalStatus;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    getAddressEmployees(data) {
      this.$refs.refAddress.$el.value = `${data.route || ""} ${
        data.street_number || ""
      }`;
      this.userData.address = `${data.route || ""} ${data.street_number || ""}`;
      this.userData.city = data.administrative_area_level_1 || "";
      this.userData.zip_code = data.postal_code || "";
      this.userData.state = data.locality || "";
    },
  },
};
</script>

<style lang="scss">
.avatar-container {
  position: relative;

  .remove-temporal-avatar {
    display: none;
    transition: all 0.5s ease-in-out;
  }

  &.backdrop:hover {
    &::after {
      position: absolute;
      content: "";
      background: grey;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      z-index: 10;
      top: 0px;
      left: 0px;
    }

    .remove-temporal-avatar {
      cursor: pointer;
      position: absolute;
      z-index: 100;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      display: flex !important;
      justify-content: center;
      align-items: center;

      div {
        width: 45px;
        height: 45px;
        background: transparent !important;
        border: 3px white solid;
        outline: none;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        z-index: 100;
      }
    }
  }
}

@media screen and(max-width: 1200px) and (min-width: 968px) {
  .information-form {
    margin-top: 30px;
  }
}
</style>
