<template>
  <div>
    <header-slot>
      <template #actions>
        <b-row class="justify-content-end mr-1">
          <b-button
            id="button-open-modal-essential-documments"
            :variant=" pendingUploadDocumentsCounter + rejectedDocumentsCounter>0 ? 'info': 'outline-info'"
            class="mr-1"
            @click="modalEssentialDocumemntsShow = true"
          >
            {{ pendingUploadDocumentsCounter + rejectedDocumentsCounter>0 ? 'Upload Essential Documments': 'Essential Documments' }}
            <b-badge
              v-if="pendingUploadDocumentsCounter + rejectedDocumentsCounter>0"
              variant="danger"
            >
              {{ pendingUploadDocumentsCounter + rejectedDocumentsCounter }}
            </b-badge>
          </b-button>
          <b-button
            variant="outline-danger"
            :to="currentUser.user_id==1?'/home':'/dashboard'"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            class="ml-1"
            @click="updateProfile"
          >
            Save
          </b-button>
        </b-row>
      </template>
    </header-slot>

    <!-- Alert: No item found -->
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="currentUser === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list' }"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>
    <template v-if="currentUser">
      <!-- First Row -->
      <b-row>
        <b-col cols="12">
          <user-view-user-info-card
            ref="userViewUserInfoCard"
            :user-data="currentUser"
            :form="form"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <!--user access-->
          <user-view-access-card :user-data="currentUser" />
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <!--session list-->
          <user-view-session-list-card />
        </b-col>
      </b-row>
    </template>

    <b-modal
      id="modal-essential-documments"
      v-model="modalEssentialDocumemntsShow"
      size="xl"
      title="Upload Essential Documments"
      :hide-footer="true"
    ><div class="mt-3 mx-auto d-block text-center">
       <b-button variant="outline-primary">
         Pending to upload : {{ pendingUploadDocumentsCounter }}
       </b-button>
       <b-button
         class="ml-1"
         variant="outline-warning"
       >
         Pending to approve : {{ pendingApproveDocumentsCounter }}
       </b-button>
       <b-button
         class="ml-1"
         variant="outline-success"
       >
         Approved : {{ approvedDocumentsCounter }}
       </b-button>
       <b-button
         class="ml-1"
         variant="outline-danger"
       >
         Rejected : {{ rejectedDocumentsCounter }}
       </b-button>
     </div>
      <p class="my-4">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Documment</th>
              <th>File name</th>
              <th>View document</th>
              <th>Status</th>
              <th>Updated at</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="employeeDocumment in employeeDocumments"
              :key="employeeDocumment.id"
            >
              <td>
                {{ employeeDocumment.name }}
                <small v-if="employeeDocumment.name === 'Certificado Único Laboral o Antecedentes Penales'">
                  <br>
                  <a href="https://www.empleosperu.gob.pe/CertificadoUnicoLaboral/">More info</a>
                </small>
              </td>
              <td>
                <b-form-file
                  v-if="employeeDocumment.status === 'pending to upload' || employeeDocumment.status === 'rejected'"
                  v-model="employeeDocumment.file"
                  :placeholder="employeeDocumment.file_name"
                  accept=".jpg, .png, .docx, .pdf"

                  @change="uploadEssentialDocument($event,employeeDocumment)"
                />
                <strong
                  v-else
                  class="text-warning"
                >{{ employeeDocumment.file_name }}</strong>
              </td>
              <td>
                <b-button
                  v-if="employeeDocumment.url"
                  variant="outline-primary"
                  @click="contentClicked(employeeDocumment.url)"
                ><feather-icon
                  icon="EyeIcon"
                />
                </b-button>
              </td>
              <td>
                <strong
                  :class="{
                    'text-primary': employeeDocumment.status === 'pending to upload',
                    'text-warning': employeeDocumment.status === 'pending to approve',
                    'text-success': employeeDocumment.status === 'approved',
                    'text-danger': employeeDocumment.status === 'rejected'
                  }"
                >
                  {{ toTitleCase(employeeDocumment.status) }}
                </strong>
              </td>
              <td>
                <feather-icon
                  icon="CalendarIcon"
                  size="20"
                />
                {{ employeeDocumment.updated_at | myGlobalDay }}
              </td>
            </tr>
          </tbody>
        </table>
      </p>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Service from "./profile.service";
import UserViewUserInfoCard from "./UserViewUserInfoCard.vue";
import UserViewAccessCard from "./UserViewAccessCard.vue";
import UserViewSessionListCard from "./UserViewSessionListCard.vue";

export default {
  components: {
    UserViewUserInfoCard,
    UserViewAccessCard,
    UserViewSessionListCard,
  },
  data() {
    return {
      modalEssentialDocumemntsShow: false,
      employeeDocumments: [],
      pendingUploadDocumentsCounter: 0,
      pendingApproveDocumentsCounter: 0,
      approvedDocumentsCounter: 0,
      rejectedDocumentsCounter: 0,
      oldCurrentUserData: {},
      file: null,
      fileName: null,
      form: {
        file: "",
        image: "",
        remove: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  created() {
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
    this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", { type: "sticky" });
    this.initializeEssentialDocumments();
    this.oldCurrentUserData = JSON.parse(JSON.stringify(this.currentUser));
  },
  destroyed() {
    this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", {
      type: "sticky",
    });
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", this.menuHidden);
    this.$store.commit("appConfig/UPDATE_LAYOUT_TYPE", "vertical");
  },
  methods: {
    ...mapActions({
      A_UPDATE_PROFILE: "ProfileStore/A_UPDATE_PROFILE",
      updateCurrentUserInformation: "auth/updateCurrentUserInformation",
    }),
    async updateProfile() {
      try {
        const component = this.$refs.userViewUserInfoCard;
        const validateUserInformation =
          await component.$refs.formUserInformation.validate();

        if (!validateUserInformation) {
          return;
        }

        this.addPreloader();
        let data = {
          cellphone: this.currentUser.cellphone,
          country: this.currentUser.country,
          date_of_birth: this.currentUser.date_of_birth,
          email: this.currentUser.email,
          extension: this.currentUser.extension,
          first_name: this.currentUser.first_name,
          id: this.currentUser.user_id,
          last_name: this.currentUser.last_name,
          module: "",
          password: "",
          phone: this.currentUser.phone,
          status: this.currentUser.status,
          superid: "",
          // data of employee
          middle_name: this.currentUser.middle_name,
          pid_type: this.currentUser.pid_type,
          pid: this.currentUser.pid,
          address: this.currentUser.address,
          city: this.currentUser.city,
          zip_code: this.currentUser.zip_code,
          state: this.currentUser.state,
          marital_id: this.currentUser.marital_id,
          private_email: this.currentUser.private_email,
          pension_fund_id: this.currentUser.pension_fund_id,
          work_phone: this.currentUser.work_phone,
          gender: this.currentUser.gender,
          know_as: this.currentUser.know_as,
        };

        if (this.form.file !== "" && this.form.image !== "") {
          data = Object.assign(data, this.form);
        }

        const response = await this.A_UPDATE_PROFILE(data);

        if (response.status === 200) {
          this.showGenericToast({
            text: "User edit in successfully",
          });
          // this.currentUser.first_name = this.oldCurrentUserData.first_name;
          // this.currentUser.last_name = this.oldCurrentUserData.last_name;
          // this.currentUser.middle_name = "";
          // this.updateCurrentUserInformation({
          //   data: this.currentUser,
          //   avatar:
          //     this.form.file !== "" ? this.form.file : this.currentUser.avatar,
          // });
          this.form.remove = false;
        }

        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        throw error;
      }
    },

    async initializeEssentialDocumments() {
      try {
        this.addPreloader();
        const obj = {
          idUser: this.currentUser.user_id,
        };
        const res = await Service.initializeEssentialDocumments(obj);
        if (res.status === 200) {
          this.employeeDocumments = res.data;
          await this.initializeCounter();
          this.removePreloader();
        }
      } catch (error) {
        console.log(error);
        this.removePreloader();
      }
    },

    async initializeCounter() {
      this.pendingUploadDocumentsCounter = 0;
      this.pendingApproveDocumentsCounter = 0;
      this.approvedDocumentsCounter = 0;
      this.rejectedDocumentsCounter = 0;
      if (!this.employeeDocumments) return;
      this.employeeDocumments.forEach((document) => {
        if (document.status === "pending to upload")
          this.pendingUploadDocumentsCounter += 1;
        if (document.status === "pending to approve")
          this.pendingApproveDocumentsCounter += 1;
        if (document.status === "approved") this.approvedDocumentsCounter += 1;
        if (document.status === "rejected") this.rejectedDocumentsCounter += 1;
      });
    },

    async asyncFileLoad(event) {
      return new Promise((resolve) => {
        const accept = [
          "image/png",
          "image/jpg",
          "image/jpeg",
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ];
        const image = event.target.files[0];

        if (!image) return;
        if (!accept.includes(image.type)) {
          this.showInfoSwal("Only image or pdf Allowed", "Warning");
          return;
        }
        if (image.size > 5 * 1024 * 1024) {
          this.showWarningSwal("The file size must not exceed 5MB");
          return;
        }

        this.fileName = image.name;

        const reader = new FileReader();
        reader.readAsDataURL(image);

        reader.onload = () => {
          resolve(reader.result);
        };
      });
    },

    async uploadEssentialDocument(event, document) {
      const { isConfirmed } = await this.showConfirmSwal();
      if (!isConfirmed) {
        return;
      }

      this.file = await this.asyncFileLoad(event);
      try {
        this.addPreloader();
        const obj = {
          idUser: this.currentUser.user_id,
          idModule: this.currentUser.main_module,
          idFileModule: document.id,
          file: this.file,
          fileName: this.fileName,
        };
        const res = await Service.uploadEmployeeEssentialDocument(obj);
        if (res.status === 200) {
          this.file = null;
          this.initializeEssentialDocumments();
          this.removePreloader();
          this.showSuccessSwal();
        }
      } catch (error) {
        console.log(error);
        this.removePreloader();
        this.showWarningSwal(error);
      }
    },

    async contentClicked(url) {
      window.open(url, "_blank");
    },

    toTitleCase(inputString) {
      return inputString
        .toLowerCase()
        .replace(/^(.)|\s(.)/g, (match) => match.toUpperCase());
    },
  },
};
</script>
<style scoped>
.content-header-right {
  display: block !important;
}
</style>
