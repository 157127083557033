<template>
  <div>
    <b-nav class="border-bottom-info">
      <b-nav-item
        :to="{ name: 'users-my-profile-other-information' }"
        exact
        exact-active-class="bg-info text-white"
        :link-classes="['sub-tab px-3 py-1 border-top-info border-left-info border-right-info rounded-top mr-1', bgTabsNavs]"
      >
        Other Information
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'users-my-profile-account-information' }"
        exact
        exact-active-class="bg-info text-white"
        :link-classes="['sub-tab px-3 py-1 border-top-info border-left-info border-right-info rounded-top mr-1', bgTabsNavs]"
      >
        Account Information
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'users-my-profile-work-experiencie' }"
        exact
        exact-active-class="bg-info text-white"
        :link-classes="['sub-tab px-3 py-1 border-top-info border-left-info border-right-info rounded-top mr-1', bgTabsNavs]"
      >
        Work Experiencie
      </b-nav-item>
    </b-nav>
    <router-view
      :key="$route.name"
    />
  </div>
</template>
<script>
export default {
};
</script>
