var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"form"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Current Password","label-for":"current-password"}},[_c('validation-provider',{attrs:{"vid":"current","name":"current","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"id":"current-password","type":"password","placeholder":"Current Password","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.userData.currentPassword),callback:function ($$v) {_vm.$set(_vm.userData, "currentPassword", $$v)},expression:"userData.currentPassword"}}),_c('b-form-invalid-feedback',[_vm._v(" Current Password "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"New Password","label-for":"new-password"}},[_c('validation-provider',{attrs:{"rules":"required|specialpassword|password:@confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"id":"new-password","type":"password","placeholder":"New Password","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.userData.newPassword),callback:function ($$v) {_vm.$set(_vm.userData, "newPassword", $$v)},expression:"userData.newPassword"}}),_c('b-form-invalid-feedback',[_vm._v(" New Password "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Confirm Password","label-for":"confirm-password"}},[_c('validation-provider',{attrs:{"rules":"required","name":"confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"id":"confirm-password","type":"password","placeholder":"Confirm Password","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.userData.confirmPassword),callback:function ($$v) {_vm.$set(_vm.userData, "confirmPassword", $$v)},expression:"userData.confirmPassword"}}),_c('b-form-invalid-feedback',[_vm._v(" Confirm Password "+_vm._s(errors[0])+" ")])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }