/* eslint-disable no-useless-catch */
/* eslint-disable class-methods-use-this */
import { amgApi } from '@/service/axios';

class ProfileService {
  async initializeEssentialDocumments(body) {
    try {
      const { data, status } = await amgApi.post('/profile/initialize-essential-documments', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async uploadEmployeeEssentialDocument(body) {
    try {
      const { data, status } = await amgApi.post('/profile/upload-essential-documment', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }
}

export default new ProfileService();
